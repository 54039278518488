@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Open Sans", sans-serif;
}

.bold {
  font-family: "Radley", sans-serif;
}
h2,
h3 {
  font-family: "Roboto";
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* custom css */


.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Lighter background for the track */
  border-radius: 10px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c084fc; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #f1f1f1; /* Space around the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a855f7; /* Darker color on hover */
}
