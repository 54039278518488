/* Container styling */
.terms-page {
    background: #262525;
    padding: 30px 0px;
}
.terms-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 12px;
    background-color: #101010;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

/* Navigation bar styling */
.terms-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.terms-nav button {
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 25px;
    transition: color 0.3s ease, border-bottom 0.3s ease;
}

.terms-nav button:hover {
    color: #0077cc;
}

.terms-nav .active {
    border-bottom: 3px solid #0077cc;
    color: #0077cc;
}

/* Heading styling */
.terms-heading {
    font-size: 30px;
    font-weight: bold;
    color: #faf9f9;
    text-align: center;
    margin-bottom: 25px;
    border-bottom: 3px solid #ddd;
    padding-bottom: 15px;

}

/* Section styling */
.terms-section {
    margin-bottom: 25px;
}

/* Subheading styling */
.terms-subheading {
    display: block;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    padding: 15px;
    color: #ccc;
    background-color: #101010;
    border-radius: 8px;
    border-color: #fdfefe;
    border-style:none;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin: 15px 0;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    text-decoration: none;
}
/* Subsection heading styling */
.terms-subsection-heading {
    display: block;
    font-size: 22px;
    font-weight: normal;
    cursor: pointer;
    padding: 15px;
    color: #ccc;
    background-color: #101010;
    border-radius: 8px;
    border-color: #fdfefe;
    border-style:none;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin: 15px 0;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    text-decoration: none;
}

.terms-subheading span {
    color: #f5f7f8; /* Ensure links within subheading have the correct color */
    text-decoration: none; /* Remove underline from links */
}
.terms-subheading:hover {
    background-color: #d3d3eb35;
    color: #003366;
}

/* Paragraph styling */
.terms-paragraph {
    font-size: 18px;
    line-height: 1.6;
    color: #faf9f9;
    padding: 15px;
    border-left: 5px solid #4261b2;
    background-color: #3f3e3e71;
    margin: 15px 0;
    transition: max-height 0.3s ease-out, padding 0.3s ease-out;
    overflow: hidden;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.symbol {
    font-size: 24px;
    font-weight: bold;
    float: right;
    }
