body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #11002e;
}

.container {
  text-align: center;
  display: flex;
  background-color: #000000;
  color: white;
  position: absolute;
  top: 75px;
  bottom: 0;
  right: 0;
  left: 0;
}

.section-name-input {
  background: transparent;
  border: none;
  outline: none;
  color: transparent;
  caret-color: rgb(252, 251, 251); /* Make caret (text cursor) black */
  width: 80%;
  font-size: 1rem; /* Larger font size */
  text-align: center; /* Center align text */
}
.section-name-input:focus {
  background-color: #361b63 !important; /* Change to red when hovered */
  outline: 1px solid white;
}

.left-side-menu-button:hover {
  background-color: #020007; /* Change to your desired active background color */
  color: white; /* Change to your desired active text color */
}
.left-side-menu-button.active {
  background-color: #4f318a; /* Change to your desired active background color */
  color: white; /* Change to your desired active text color */
}
.left-side-menu-button.active span {
  color: white; /* Change to your desired active text color */
}

.header {
  display: flex;
  background: linear-gradient(
    228.05deg,
    #81f8bf 12.76%,
    #3c88f1 35.58%,
    #8749e7 63.06%,
    #cb1ccd 89.1%
  );
  color: white;
  height: 75px;
  font-size: 30px;
  font-family: "Radley", serif;
  margin-bottom: 0px;
}

.header h1 {
  margin-top: 0;
  margin-left: 15px;
}

.header-btns-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 70px;
}

.header-btns {
  background-color: #ebeaf5;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
  margin: 32px 6px;
  border-radius: 5px;
  font-family: "Radley", serif;
  font-size: 15px;
  border: none;
  width: 90px;
  height: 30px;
}

.left-sidemenu {
  width: 200px;
  padding: 10px;
  margin: 10px 1px;
  background-color: #000000;
  border: solid 3px #1a0052;
}

.form {
  flex: 1;
  margin: 5px;
  flex-direction: column;
  color: white;
  background-color: #11002e;
  position: relative;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form input {
  width: 70%;
  padding: 2px;
  padding-left: 5px;
  border: 5px whitesmoke;
  text-align: auto;
  font-size: 16px;
  color: white;
  border: none;
  background-color: transparent; /* Make the input transparent */
  margin-left: 5px;
  margin-right: 5px;

  transition: border 0.3s ease, background-color 0.3s ease color 0.3 ease;
  box-sizing: border-box;
}
.form input:not(:placeholder-shown) {
  border-color: #0a011a;
  background-color: #2e0f68;
}
.form input:-webkit-autofill {
  /* Custom background color for autofill */
  /* Set the text color for autofilled inputs */
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #2e0f68 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.form input:focus {
  border-color: #ffefef;
  background-color: #361b63;
  color: rgb(233, 220, 220);
}
.form input:hover {
  background-color: #361b63;
  color: rgb(233, 220, 220);
}


/* Due Date CSS */
.MuiFormControl-root {
  width: 70%;
  margin-left: 5px;
  margin-right: 15px;
  padding: 2px;
  background-color: transparent;
  color: white;
  font-size: 16px;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
  padding: 5px;
}

.MuiSvgIcon {
  color: rgba(255, 255, 255, 0.6) !important;
}

.MuiPickersCalendarHeader-switchViewIcon {
  color: black !important;
}

.MuiSvgIcon-fontSizeInherit {
  color: black !important;
}

.top {
  font-size: 34px;
  align-items: center;
  margin-bottom: 0px;
  margin-top: -62px;
}

.button {
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 98%;
  padding: 10px;
  border: black; /* Remove default input border */
  font-size: 20px;
  box-shadow: 0 6px 4px #050505c5;
  background-color: #5e36a3; /* Make the input transparent */
  user-select: none;
}

.button:hover {
  border: black; /* Remove default input border */
  font-size: 22px;
  background-color: #6d60eb; /* Make the input transparent */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  outline: 3px solid #b093e4;
  transition: font-size 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
}

.button:active {
  border: rgb(255, 255, 255); /* Remove default input border */
  font-size: 20px;
  background-color: #5e36a3; /* Make the input transparent */
  box-shadow: 0 12px 24px rgba(22, 22, 22, 0.3);
  outline: 3px solid #b093e4;
  box-shadow: 0 6px 4px #050505c5;
  transition: font-size 0.1s ease, background-color 0.1s ease,
    box-shadow 0.1s ease;
}

.left-side-menu-button {
  padding: 15px;
  border: 1px solid white;
  border-radius: 5px;
  text-align: left;
  cursor: pointer;
  transition: ease 0.25s all;
}

.left-side-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.left-side-menu-button span {
  padding-left: 6px;
  padding-right: 12px;
}

.chat-input-holder {
  padding: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.chat-input-textarea {
  padding: 12px 0 0 12px;
  display: flex;
  position: relative;
  background-color: #11002E;
  border: 2px solid #5406d2;
  box-sizing: border-box;
  width: 100%;
  color: white;
  font-size: 1.25em;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

.chat-input-textarea .send-btn {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  width: 80%;
  height: 40%;
  margin: 0 auto;
  color: white;
  background: linear-gradient(
    228.05deg,
    #81f8bf 12.76%,
    #3c88f1 35.58%,
    #8749e7 63.06%,
    #cb1ccd 89.1%
  );
}

.send-button-holder-div {
  width: 10%;
  height: 100%;
  align-items: flex-end;
  display: flex;
  flex: 0.15;
  margin-left: 5px;
}

.text-field-bottom-div {
  display: flex;
  position: relative;
  margin: 0px 4px 5px 7px;
  width: 100%;
  height: 11vh;
}

.user-input-form {
  width: 85%;
  flex: 1 1;
}

.text-field-bottom {
  resize: none;
  caret-color: white;
  color: white;
  width: 100%;
  height: 100%;
  border: 0.5px solid rgba(84, 6, 210, 0.6);
  font-family: "Roboto";
  background: #0f0126;
  padding-left: 12px;
  box-sizing: border-box;
}

.chat-log {
  padding: 0px;
  padding-right: 30px;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 10px;
  text-align: left; 
  max-height: 77%;
}

.chat-message.chatgpt {
  margin-bottom: 12px;
  background-color: #11002E;
  ; 
}

.chat-message {
  position: relative;
  background-color: #380093; 
}

.QandADiv {
  padding: 24px;
  text-align: left;
}

.chat-message.AI {
  margin-bottom: 12px;
  background-color: #11002E; 
}

.chat-message {
  position: relative;
  background-color: #380093; 
}

.redo-btn-div {
  position: absolute;
  bottom: 1px;
  right: 13px;
}

.redo-btn {
  height: 22px;
}

.chat-message-center {
  padding: 12px;
  display: flex;
  max-width: 100%;
}

.avatar {
  width: 40px;
  height: 40px;
  background: center/cover no-repeat;
  background-image: url("https://raw.githubusercontent.com/mahshidroshan/Asset/642cc99a22d88b9dd337cbe2d1b8372fdf27387c/Question_Icon.svg");
}

.avatar-and-btns-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.word-char-count-btn {
  display: flex;
}

.word-count,
.character-count {
  border-radius: 3px;
  border: 1px black solid;
  font-size: 16px;
  background-color: #5406d2;
  color: white;
  height: 29px;
  width: 35px;
}

.total-number-field {
  border-radius: 3px;
  border: #000000;
  margin-top: 3px;
  font-size: 16px;
  background-color: #5406d2;
  color: white;
  width: 65px;
  height: 22px;
  text-align: center;
}

/*Hide Arrows From Input Number*/
/* for chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* for mozilla */
input[type="number"] {
  -moz-appearance: textfield;
}

.avatar.AI {
  background: center/cover no-repeat;
  background-image: url("https://raw.githubusercontent.com/mahshidroshan/Asset/642cc99a22d88b9dd337cbe2d1b8372fdf27387c/AI_Icon.svg");
}

.avatar-number-field-AI {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  padding-left: 20px;
  padding-right: 40px;
}

.tab-bar-div {
  width: 70%;
  height: 10px;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 17%;
  display: flex;
  flex-direction: row;
}

.tab {
  width: 20%;
  border-right: 1px solid black;
  height: 100%;
  background-color: yellow;
}

.tab.selected {
  background-color: white;
}


.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Lighter background for the track */
  border-radius: 10px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c084fc; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #f1f1f1; /* Space around the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a855f7; /* Darker color on hover */
}

/* css styles to make input field text transparent */
.transparent-placeholder::-webkit-input-placeholder {
  opacity: 25%;
  font-size: medium;
}

.transparent-placeholder::-moz-placeholder {
  opacity: 25%;
  font-size: medium;
}

.transparent-placeholder:-ms-input-placeholder {
  opacity: 25%;
  font-size: medium;
}

.transparent-placeholder:-moz-placeholder {
  opacity: 25%;
  font-size: medium;
}
.form-item-styles {
  margin-left: -5px;
}

.form-item-styles input {
  border: 1.5px solid #19039E;
  width: 70%;
}

.form-item-styles input:focus {
  border: 1.5px solid none;
  width: 70%;
}