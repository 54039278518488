body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section-name-input {
  background: transparent;
  border: none;
  outline: none;
  color: transparent;
  caret-color: rgb(252, 251, 251); /* Make caret (text cursor) black */
  width: 80%;
  font-size: 1rem; /* Larger font size */
  text-align: center; /* Center align text */
}

.header {
  display: flex;
  background: linear-gradient(
    228.05deg,
    #81f8bf 12.76%,
    #3c88f1 35.58%,
    #8749e7 63.06%,
    #cb1ccd 89.1%
  );
  color: white;
  height: 75px;
  font-size: 30px;
  font-family: "Radley", serif;
  margin-bottom: 0px;
}

.header h1 {
  margin-top: 0;
  margin-left: 15px;
}

.header-btns-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 70px;
}

.header-btns {
  background-color: #ebeaf5;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
  margin: 32px 6px;
  border-radius: 5px;
  font-family: "Radley", serif;
  font-size: 15px;
  border: none;
  width: 90px;
  height: 30px;
  cursor: pointer;
}

.user-input-form {
  width: 85%;
  flex: 1 1;
}

.text-field-bottom {
  resize: none;
  caret-color: white;
  color: white;
  width: 100%;
  height: 100%;
  border: 0.5px solid rgba(84, 6, 210, 0.6);
  font-family: "Roboto";
  background: #0f0126;
  padding-left: 12px;
  box-sizing: border-box;
}

.QandADiv {
  padding: 24px;
  text-align: left;
}

.avatar {
  width: 40px;
  height: 40px;
  background: center/cover no-repeat;
  background-image: url("https://raw.githubusercontent.com/mahshidroshan/Asset/642cc99a22d88b9dd337cbe2d1b8372fdf27387c/Question_Icon.svg");
}

.avatar-and-btns-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.word-char-count-btn {
  display: flex;
}

.word-count,
.character-count {
  border-radius: 3px;
  border: 1px black solid;
  font-size: 16px;
  background-color: #5406d2;
  color: white; 
  width: 35px;
}

.total-number-field {
  border-radius: 3px;
  border: #000000;
  margin-top: -3px;
  font-size: 16px;
  background-color: #5406d2;
  color: white;
  width: 65px;
  height: 22px;
  text-align: center;
}

/*Hide Arrows From Input Number*/
/* for chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* for mozilla */
input[type="number"] {
  -moz-appearance: textfield;
}

.avatar.AI {
  background: center/cover no-repeat;
  background-image: url("https://raw.githubusercontent.com/mahshidroshan/Asset/642cc99a22d88b9dd337cbe2d1b8372fdf27387c/AI_Icon.svg");
}

.avatar-number-field-AI {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  padding-left: 20px;
  padding-right: 40px;
}

.tab-bar-div {
  width: 70%;
  height: 10px;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 17%;
  display: flex;
  flex-direction: row;
}

.tab {
  width: 20%;
  border-right: 1px solid black;
  height: 100%;
  background-color: yellow;
}

.tab.selected {
  background-color: white;
}

.plus {
  margin-top: 60px;
  align-items: center;
  text-align: center;
  font-size: 30px;
}

.create-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the left */
  margin-bottom: 0px; /* Add some spacing between create-container sections */
}

.create {
  margin: 30px;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: -5px;
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 10px;
  background-color: #6826e2;
  border-radius: 15px;
  cursor: pointer;
}

.existing {
  height: 43px;
  font-weight: 800;
  font-size: 16px;
  margin-left: 10px; 
  display: flex;
  width: 98.5%;
  align-items: center;
  padding: 8px;
  border: 3px solid #5406D2;
  background-color: #250062;
}

.create:hover {
  background-color: #802cc5;
  text-shadow: #000000;
  transition: ease 0.3s;
}

.button2{
  width: 260px;
  height: 45px;
  top: 72px;
  left: 472px;
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid white;
  background-color: #380093;
  border-radius: 8px;
  color: white;
  text-align: center; 
  margin: 10px; 
  font-weight: 800;
}

.button2:hover{
  background-color: #802cc5;
  text-shadow: #000000;
  transition: ease 0.3s;
}

.info-text {
  margin-left: 25px;
  margin-top: 10px;
  background-color: transparent;
  font-size: medium;
  align-items: flex-start;
}

.dash-2 {
  display: flex;
  flex-direction: row; /* Change this from row to column */
  background-color: #11002e;
  color: white;
  position: absolute;
  text-align: center;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 2px; 
}

.dash {
  margin-top: 0px; 
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  color: white;
  background-color: #11002e;
  position: relative;
  overflow: auto; 
}

.proposal-dashboard {
  display: flex;
  flex-direction: column; /* Change this from row to column */
  background-color: #11002e;
  color: white;
  position: absolute;
  text-align: center;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 2px; 
}

.dashboard-box {
  background-color: #3b265c;
  width: 260px; /* Adjust the width as needed */
  height: 150px; /* Adjust the height as needed */
  border: 2px solid #ddd;
  white-space: normal;
  border-radius: 5px;
  overflow-wrap: break-word;
  padding: 5px;
  margin: 6px;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between; */
  overflow:visible; /* Clip overflowing content */
  font-size: 10px;
  line-height: 15px;
  cursor: pointer;
}

.dashboard-box:hover {
  background-color: #210842; /* Change background color on hover */
  transition: background-color 0.3s ease; /* Add transition effect */
}

.dashboard-box:hover .plus {
  font-size: 36px;
  transition: background-color 0.3s ease;
}

.dashboard-box.clicked {
  background-color: initial;
  transition: background-color 0.3s ease; /* Reset background color */
}

/* Apply styles to .plus inside .dashboard-box when clicked */
.dashboard-box.clicked .plus {
  font-size: initial; /* Reset font size */
  transition: background-color 0.3s ease; /* Reset color */
}

.dashboard-box h2 {
  margin: 0; /* Remove default margin for h2 */
  font-size: medium;
}

.dashboard-box p {
  margin: 0; /* Remove default margin for paragraphs */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ... to indicate text overflow */
  /* Prevent text from wrapping */
  margin-bottom: 1px;
}

.clamp-two-lines {
  /* Limit text to two lines */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}
.button {
  margin-top: auto; /* Push the button to the bottom */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
}

th {
  background-color: #f2f2f2;
}

.category1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  font-family: Radley;
  padding: 4px;
}

.category5 {
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  font-family: Radley;
  padding: 8px;
  text-align: center;
}

.category1 p {
  margin: -2px;
}

.category2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding: 4px;
}
.category2 p {
  margin: -2px;
}

.category3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  font-style: bold;
  line-height: 16px;
  color: #ffffff;
}

.category4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 10px;
  line-height: 20px;
  color: #ffffff;
  padding: 4px;
}

.category4 p {
  margin: -2px;
}

.text-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start (top) */
}

.button1 {
  width: 260px;
  height: 45px; 
  border: 3px solid #A85101;
  background-color: #813E00;
  color: white; 
  margin: 10px; 
  font-weight: 800;
} 

.search-bar { 
  width: 513px;
  height: 45px; 
  background-color: #250062;
  border: 3px solid #5406D2;
  padding: 10px;
  font-size: 16px;
  margin: 10px;  
}

.inner-section {  
  height: 44px;
  top: 154px;
  left: 472px;
  border: 3px solid #5406D2;
  background-color: #250062;
  color: white;
  padding: 8px;
  margin: 10px; 
  font-weight: 800;
  display: flex;
}  

.left-client-sidemenu { 
  width: 100%;
  height: 100%;  
  border: solid 3px #1a0052;
  background-color: #150b2c;
  align-content: start;
}

.client-add-btn {
  width: 97%;
  height: 30px;
  color: white;
  font-size: 18px; 
  margin-left: 5px !important;
  justify-content: flex-start !important;  
  align-items: center;
  background-color: #380093;
  border: solid 3px #5406D2;
}

.client-name-div {
  height: 45px;
  width: 98.5%;
  color: white;
  font-size: 15px;
  background-color: #380093;
  border: solid 3px #1B4595;
  margin: 5px 0;
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px;
}

.client-list{
  padding-left: 2%;
} 

.Commands {
  position: relative;
  top: -100px;
  padding: 16px;
  width: 93%;
  height: 300px;
  background-color: #1D093C;
  border: 1px solid #5406D2;
  color: white;
  z-index: 50;
  margin-bottom: 5%; 
  border-radius: 8px;  
  overflow-y: auto;
}

.Commands::-webkit-scrollbar {
  width: 4px;
  margin-right: 1%;
}

.Commands::-webkit-scrollbar-thumb {
  background-color: #5406D2; 
}

.Commands ul {
  list-style-type: none; 
  padding: 0;
}

.Commands li {
  margin-bottom: 8px; 
} 

.Commands .table-of-content {
  margin: 0;
  padding: 0;
}

.Commands .table-of-content li {
  margin: 0;
  padding: 0;
}

.blackIcon{
  color: #00068e !important;
}