

/* General Styles */
  body {
    margin: 0;
    font-family: Arial, sans-serif;
  }  
  
  .client-dash {
    display: flex;
    flex-direction: column;
    background-color: #11002e;
    color: white;
    position: absolute;
    text-align: center;
    bottom: 0;
    right: 0;
    left: 3.2rem;
    overflow: auto;
    padding: 2px; 
  } 

  .sidebar{ 
    /* height: 90% !important; */
    min-height: 48.1rem;
    max-height: 59rem;
  }
  
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  } 
  
  .search-bar {
    padding: 10px;
    background-color: #250062;
    border: none;
    color: white;
    width: 200px;
  }

  .button1 {
    width: 260px;
    height: 45px; 
    border: 3px solid #A85101;
    background-color: #813E00;
    color: white; 
    margin: 10px; 
    font-weight: 800;
  } 
  
  .proposal-list {
    display: flex;
    flex-direction: column; 
  }
  
  .client-proposal-row {
    height: 50px;
    display: flex;
    align-items: center; 
    margin-bottom: 5px;
  }
  
  .bg-dark {
    background-color: #250062;
  }
  
  .bg-light {
    background-color: #3a007e;
  }
  
  .client-name {
    background: #250062;
    border: 3px solid #5406D2;
    color: white;
    font-weight: bold;
    margin-right: 10px;
    height: 50px !important;
    padding-top: 0.5%;
  }
  
  .proposal-card {
    height: 30px;
    background-color: #11002e;
    color: white; 
    justify-content: space-between;
    margin-right: 10px;
    width: 170px;
  }
  
  .proposal-card-header {
    font-size: small;
    width: 150px;
  }
  
  .proposal-card.due {
    border: 2px solid #FF9900; 
  }
  
  .proposal-card.awarded {
    border: 2px solid #22F400;
  }

  .proposal-card.draft {
    border: 2px solid #FFF384;
  }
  
  .proposal-card.in-progress {
    border: 2px solid #FFFFFF; 
  }

  .proposal-card.done {
    border: 2px solid #4886FF; 
  }

  .infoIcon.due {
    color: #FF9900; 
  }
  
  .infoIcon.awarded {
    color: #22F400;
  }

  .infoIcon.draft {
    color: #FFF384;
  }
  
  .infoIcon.in-progress {
    color: #FFFFFF;
  }

  .infoIcon.done {
    color: #4886FF;
  }

  .proposal-card-body p {
    margin: 5px 0;
  }