.tableofcontent-container {
  padding: 10px;
  color: #fff;
  border-radius: 8px; 
  overflow-y: scroll;  
  max-height: 98%;
  height: 82vh;
}

.tableofcontent {
  font-weight: bold;
  margin-bottom: 10px; 
}

.table-of-content {
  list-style: none;
  padding: 0;
  text-align: left;
}

.question-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px; 
}

.question-number {
  font-weight: bold;
  margin-right: 5px;
}

.question-text {
  /* flex: 1; */
  word-wrap: break-word;
  white-space: pre-line;
}

/* //FileUploader */

.upload-btn {
  width: 97%;
  height: 50px;
  color: white;
  font-size: 18px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  background-color: #380093;
  border: solid 3px #5406D2;
}

.upload-btn-divs {
  padding-right: 2%;
}

.uploaded-file {
  margin-top: 2%;
  width: 97%;
  padding: 2%;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: black;
  border: solid 3px #19039e;
}

.file-upload-btns {
  display: flex;
  justify-content: space-evenly;
}

/* WrapperPlan  */

.fade{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999999;
}
  

.container-wrapper{
  width: 100%;
  height: 100%;
  overflow: auto;
 
}

.container-items{
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:40px;
}

@media screen and (max-width: 768px) {
  .container-wrapper{
    padding-top: 20px;
  }

  .fade__item{
    width: 80% !important;
    
  }
}

.fade__item{
  width: 25%;
  min-width: 280px;
  height: 500px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: black;
  animation: slide-down 0.5s backwards;
}


@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* newclient-sidemenu */

.left-client-sidemenu { 
  width: 100%;
  height: 100%;  
  border: solid 3px #1a0052;
  background-color: #150b2c;
  align-content: start;
}

.client-add-btn {
  width: 97%;
  height: 30px;
  color: white;
  font-size: 18px; 
  margin-left: 5px !important;
  justify-content: flex-start !important;  
  align-items: center;
  background-color: #380093;
  border: solid 3px #1b4595;
}

.client-name-div {
  height: 45px;
  width: 98.5%;
  color: white;
  font-size: 15px;
  background-color: #380093;
  border: solid 3px #1B4595;
  margin: 5px 0;
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px;
}

.client-list{
  padding-left: 2%;
} 

.Commands {
  position: relative;
  top: -100px;
  padding: 16px;
  width: 93%;
  height: 300px;
  background-color: #1D093C;
  border: 1px solid #5406D2;
  color: white;
  z-index: 50;
  margin-bottom: 5%; 
  border-radius: 8px;  
  overflow-y: auto;
}

.Commands::-webkit-scrollbar {
  width: 4px;
  margin-right: 1%;
}

.Commands::-webkit-scrollbar-thumb {
  background-color: #5406D2; 
}

.Commands ul {
  list-style-type: none; 
  padding: 0;
}

.Commands li {
  margin-bottom: 8px; 
} 

.Commands .table-of-content {
  margin: 0;
  padding: 0;
}

.Commands .table-of-content li {
  margin: 0;
  padding: 0;
}

.blackIcon{
  color: #00068e !important;
}

/* ChatMessage */

.gpt-f, .gpt-fo{
  border-radius: 3px;
  border: 1px black solid;
  font-size: 16px; 
  color: white; 
  width: 35px;
} 

.gpt-f:active{ 
  border: 1px solid #fff; 
}

.gpt-fo:active{ 
  border: 1px solid #fff; 
}

.word-count:checked{ 
  border: 1px solid #fff; 
}